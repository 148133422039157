import React from 'react';
import { Container, Row } from 'react-bootstrap';

import o1 from "../images/o1.jpg";
import o2 from "../images/o2.jpg";
import o3 from "../images/o3.jpg";
import o4 from "../images/o4.jpg";
import o5 from "../images/o5.jpg";
import o6 from "../images/o6.jpg";
import custom_photo_frame from "../images/custom_photo_frame.jpg";
import magic_mirror from "../images/magic_mirror.jpg";
import custom_mug from "../images/custom_mug.jpg";
import cushion_cover from "../images/cushion_cover.jpg";

const services = [
    {
        name: "Stamps",
        image: o1
    },
    {
        name: "Leaflets & Pamphlets",
        image: o2
    },
    {
        name: "Flex & Standees",
        image: o3
    },
    {
        name: "Wedding Cards",
        image: o5
    },
    {
        name: "Offset Printing",
        image: o6
    },
    {
        name: "Custom Mug",
        image: custom_mug
    },
    {
        name: "Magic Mirror",
        image: magic_mirror
    },
    {
        name: "Cushion Cover",
        image: cushion_cover
    },
    {
        name: "Custom Photo Frame",
        image: custom_photo_frame
    },
    {
        name: "A lot more...",
        image: o4
    },
]
function OurWorkSection() {
    const cards = services.map((service) =>
        <div className="col-lg-3 col-md-6" key={service.name}>
            <div className="single-service">
                <div className="thumb">
                    <img src={service.image} alt="" />
                </div>
                <h4>{service.name}</h4>
            </div>
        </div>
    );
    return (
        <section id="work" className="work-section">
            <Container>
                <div className="row justify-content-center">
                    <div className="col-md-12 pb-50 header-text text-center">
                        <h1>What We Offer</h1>
                    </div>
                </div>
                <Row className="align-items-center">
                    {cards}
                </Row>
            </Container>
        </section >
    );
}

export default OurWorkSection;