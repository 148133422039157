import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function ContactSection() {
    return (
        <section id="contact" className="work-process-area section-gap">

            <div className="container no-padding no-margin">
                <div className="row d-flex justify-content-center">
                    <div className="menu-content pb-60 col-lg-7 ">
                        <div className="title text-center">
                            <h1>How to Reach Us</h1>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col sm={6} >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.188265099758!2d75.82201781504466!3d26.89751958313415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xce1fe87a942b37bb!2sRaj+Printers+%26+Associates!5e0!3m2!1sen!2sin!4v1542214686218"
                            className="map"
                            style={{ border: 0 }}
                            title="Location" allowFullScreen></iframe>
                        <br /><br />
                    </Col>
                    <Col sm={6} >
                        <ul style={{ marginLeft: "10px" }}>
                            <li>
                                <h3>Deepak Parnami</h3>
                            </li>
                            <li>Call us on</li>
                            <li><a href="tel:+919982066620" target="_blank" rel="noreferrer">+91-99820-666-20&nbsp;<FontAwesomeIcon icon={faPhone} /></a></li>
                            <li><a href="tel:+919413341382" target="_blank" rel="noreferrer">+91-94133-413-82&nbsp;<FontAwesomeIcon icon={faPhone} /></a></li>
                            <li><a href="tel:+918233661639" target="_blank" rel="noreferrer">+91-82336-616-39&nbsp;<FontAwesomeIcon icon={faPhone} /></a></li>
                            <li>Mail us on </li>
                            <li><a href="mailto:rajprinters81@gmail.com" target="_blank" rel="noreferrer">rajprinters81@gmail.com&nbsp;<FontAwesomeIcon icon={faEnvelope} /></a></li>
                            <li>Address</li>
                            <li><a href="https://goo.gl/maps/NWfRvrmZGUU2" target="_blank" rel="noreferrer">Basement 43 To 44, Govind Marg,<br />
                                Gurunanakpura, Raja Park,<br />
                                Jaipur, Rajasthan 302004</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </section >
    );
}

export default ContactSection;