import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import logo from "../images/logo-white.png";

function HomeSection() {
    return (
        <section id="stamps" className="home-section">
            <Container fluid={false}>
                <Row >
                    <Col>
                        <div className="d-flex flex-wrap justify-content-around">
                            <img src={logo} alt="Raj Printers" />
                        </div>
                        <div className="d-flex flex-wrap justify-content-around">
                            <h1>
                                We Print Everything!
                            </h1>
                        </div>
                        <div className="d-flex flex-wrap justify-content-around">
                            Wedding Cards, Visiting Cards, Screen &amp; Offset Printing and Much More
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HomeSection;