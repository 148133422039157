import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
function OrderStampSection() {
    return (
        <section id="stamp-section" className="stamp-section">
            <Container fluid={false} className="align-items-center">
                <Row className="align-items-center">
                    <Col>
                        <center>
                            <h1>
                                Get your stamps in 3 simple steps
                            </h1>
                        </center>
                        <div className="d-flex flex-wrap justify-content-around">
                            <ol type="1" >
                                <li>Text your content on <u><a href="https://api.whatsapp.com/send?phone=919413341382">9413341382 <i className="fa fa-whatsapp"></i></a></u></li>
                                <li>Pay the amount via PayTM to 9413341382.</li>
                                <li>Pickup the stamps from our office.</li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default OrderStampSection;