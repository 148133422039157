import { Container, Row } from "react-bootstrap";
import logo from "../images/logo-big.jpg";

function AboutSection() {
    return (
        <section id="about" className="about-section">
            <Container>
                <Row>
                    <div className="col-sm-5">
                        <div className="single-footer-widget">
                            <img src={logo} alt="Raj Printers" />
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="single-footer-widget" >
                            <h6>About Us</h6>
                            <p>Your imagination is limitless, and so are our printing capabilities.</p>
                            <p>For ideas that don't fit nicely into predefined categories, we're here to help.Whether you need irregular-sized handouts to help you stand out from the competition, specialty-cut windows in your brochures and sales materials, or custom packaging and markups, we can provide the printing services you need to set your creative side free and bring your ideas to life.
                            </p>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    );
}

export default AboutSection;