import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import NavigationBar from './sections/NavigationBar';
import OrderStampSection from './sections/OrderStampSection';
import OurWorkSection from './sections/OurWorkSection';
import ContactSection from './sections/ContactSection';
import AboutSection from './sections/AboutSection';
import HomeSection from './sections/HomeSection';
function App() {
  return (
    <div className="App" id="home">
      <NavigationBar />
      <HomeSection />
      <OurWorkSection />
      <OrderStampSection />
      <ContactSection />
      <AboutSection />
    </div>
  );
}

export default App;
