import React from 'react';

import logo from "../images/logo-white.png";
import { Container, Nav, Navbar } from 'react-bootstrap';
export default function NavigationBar() {
    return (
        <Navbar sticky="top" bg="navBackground" variant="dark" expand={true}>
            <Container>
                <Navbar.Brand href="#home" className="brand"> <img src={logo} alt="" height="32" /> Raj Printers</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#stamp-section">Order Stamps</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
